.App {
  text-align: center;
  background-color: rgb(170,126,231);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1a1e32;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Additional CSS for App component */

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: calc(100vh - 100px);
  background-color: #1a1e32;
  margin: 0;
  padding: 100px 0px;
  overflow-y: auto;
}

form {
  display: inline-block;
  margin-bottom: 20px;
}

.devices {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}



.devices button {
  align-self: flex-end;
}

.devices2 button {
  align-self: flex-end;
}

.devices2 {
  max-height: 65vh;
  overflow: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

body {
  margin: 0;
  font-family: sans-serif;
}

h4 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: bold;
  color:#ffffff
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="password"] {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #262c4a;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 15px;
}

/* button:hover {
  background-color: #3784f7;
} */

.highlighted {
  background-color: #3784f7 !important;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.device {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
 margin-bottom: 10px;
  cursor: pointer;
}

.devices {
  max-height: 200px;
  overflow: auto;
}

/* .device:hover {
  background-color: #f5f5f5;
} */

.logout {
  background-color: rgb(21, 24, 40);;
  margin: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.serialnum {
  margin: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.device-button {
  border: none;
  background-color: #262c4a;
  color: #ffffff;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 4px;
  cursor: pointer;
  border-radius: 12px;
  margin: 5px 25px;
}

/* Hover effect for device buttons */
 .notdisabled:hover {
  background: linear-gradient(90deg, #2ba5ff, #4c46e7);
  color: #ffffff;
} 

.devices {
  margin-bottom: 20px;
}

.disabled {
  opacity: 0.5;
}


.webApp {
  margin: 10px
}

a {
  color: #ffffff;
  text-decoration: none;
}
.loginback {
  background-color: #f7f7f7;
  width: 80vw; /* adjust width as needed */
  height: 300px; /* adjust height as needed */
  border-radius: 12px;
  margin: 0 auto; /* horizontally center the container */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
}

.loginback form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginback label {
  margin-bottom: 10px;
}

.loginback input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray;
}

.buttonprim {
  background: linear-gradient(90deg, #2ba5ff, #4c46e7);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

  .logo{
    height: 10vh;
    margin-bottom: 20px;
  }

  .loginback h4{
    color:black
  }

  .email {
    padding-top: 30px;
  }

 .urlinput{
  display: block;
  margin: 0 auto;
  text-align: center;
  max-width: 80vw;
 }

 .status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  top: 2px;
}

.green-circle {
  background-color: green;
}

.red-circle {
  background-color: red;
}

.refresh {
  width: 20px;
  margin-right: 10px;
}

.refresh:hover {
  cursor: pointer;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 1s linear infinite;
}

.app-submission-info {
  color: white;
  /* padding-left: 80px;
  padding-right: 80px; */
  font-size: 20px;
  font-weight: bold;
}

.app-submission-section {
  color: white;
  /* padding-left: 80px;
  padding-right: 80px; */
  font-size: 20px;
  font-weight: bold;
}

.app-submission-title {
  color: white;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 20px;
  font-weight: bold;
}

.app-submission-link {
  color: red; /* Style for the link */
}


.app-store-submit-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: white;
  color: black;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.app-store-submit-button:hover {
  background-color: #f8f8f8;
}

/* Add these styles to your CSS */
.app-submission-section {
  padding: 20px;
  margin: 15px;
  max-width: 100%;
}

.app-submission-title {
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin-bottom: 1rem;
  color: #ffffff;
}

.app-submission-info {
  font-size: clamp(1rem, 3vw, 1.2rem);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.app-store-submit-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  margin: 10px 0;
}

.app-store-submit-button:hover {
  background-color: #0056b3;
}

.app-submission-link {
  color: #007bff;
  font-weight: 600;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.app-submission-link:hover {
  color: #0056b3;
}

@media (max-width: 768px) {
  .app-submission-section {
    padding: 15px;
    margin: 10px;
  }
}

.help-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #666;
  background-color: rgb(21, 24, 40);
    margin: 25px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 40px;
  cursor: pointer;
  color: black;
}

ol {
  text-align-last: left;
}

li {
  padding-bottom: 20px;
}

.url-input-container {
  position: relative;
  display: inline-block;
}

.clear-url {
  position: absolute;
  right: 160px; /* Adjust based on your layout */
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #666;
  padding: 0 8px;
}

.clear-url:hover {
  color: #333;
}